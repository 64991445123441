var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('main-modal',{attrs:{"active":true,"bodyPadding":'0 60px'},on:{"onClose":_vm.onClose},scopedSlots:_vm._u([{key:"modalHeader",fn:function(){return [_c('a-button',{staticClass:"back-btn button-without-animation",on:{"click":function($event){return _vm.onClose()}}},[_c('i',{staticClass:"anticon"},[_c('arrow')],1),_vm._v(" "+_vm._s(_vm.$t('bases.bases_list'))+" ")])]},proxy:true},{key:"modalBody",fn:function(){return [_c('div',{staticClass:"base-form-wrapp",staticStyle:{"height":"auto"}},[_c('a-form',{staticClass:"base-form create-form",attrs:{"form":_vm.form}},[_c('a-row',[_c('a-col',{attrs:{"lg":12}},[_c('a-form-item',{attrs:{"label":_vm.$t('users.first_name')}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['first_name', {rules: [
                    {required: false, message: _vm.$t('messages.requiredField')},
                    {min: 3, message: _vm.$t('messages.minLength') + 3},
                    {max: 255, message: _vm.$t('messages.maxLength') + 255}],
                  }]),expression:"['first_name', {rules: [\n                    {required: false, message: $t('messages.requiredField')},\n                    {min: 3, message: $t('messages.minLength') + 3},\n                    {max: 255, message: $t('messages.maxLength') + 255}],\n                  }]"}],attrs:{"size":"large","placeholder":_vm.$t('users.first_name_placeholder')}})],1),_c('a-form-item',{attrs:{"label":_vm.$t('users.last_name')}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['last_name', {rules: [
                    {required: false, message: _vm.$t('messages.requiredField')},
                    {min: 3, message: _vm.$t('messages.minLength') + 3},
                    {max: 255, message: _vm.$t('messages.maxLength') + 255}],
                  }]),expression:"['last_name', {rules: [\n                    {required: false, message: $t('messages.requiredField')},\n                    {min: 3, message: $t('messages.minLength') + 3},\n                    {max: 255, message: $t('messages.maxLength') + 255}],\n                  }]"}],attrs:{"size":"large","placeholder":_vm.$t('users.last_name_placeholder')}})],1),_c('a-form-item',{attrs:{"label":"Email"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['email', {rules: [
                    {required: false, message: _vm.$t('messages.requiredField')},
                    {min: 3, message: _vm.$t('messages.minLength') + 3},
                    {max: 255, message: _vm.$t('messages.maxLength') + 255}],
                  }]),expression:"['email', {rules: [\n                    {required: false, message: $t('messages.requiredField')},\n                    {min: 3, message: $t('messages.minLength') + 3},\n                    {max: 255, message: $t('messages.maxLength') + 255}],\n                  }]"}],attrs:{"size":"large","placeholder":_vm.$t('organizations.enter_email')}})],1)],1),_c('a-col',{attrs:{"lg":12}},[_c('a-form-item',{attrs:{"label":_vm.$t('organizations.organizations_list')}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:(['organization_id',{rules: [
                    {required: false, message: _vm.$t('messages.requiredField')}],
                    initialValue: _vm.selected
                  }]),expression:"['organization_id',{rules: [\n                    {required: false, message: $t('messages.requiredField')}],\n                    initialValue: selected\n                  }]"}],attrs:{"size":"large","placeholder":_vm.$t('basics.select_organization')},on:{"change":_vm.handleSelectChanges}},[_c('a-select-option',{attrs:{"value":"-1"}},[_vm._v(" "+_vm._s(_vm.$t('bases.no_organization'))+" ")]),_vm._l((_vm.organizationsList),function(item){return _c('a-select-option',{key:item.id,attrs:{"value":item.id}},[_vm._v(" "+_vm._s(item.name)+" ")])})],2)],1),_c('a-form-item',{attrs:{"label":_vm.$t('users.nfc_badge')}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['nfc_badge_id', {rules: [
                    {required: false, message: _vm.$t('messages.requiredField')},
                    {min: 3, message: _vm.$t('messages.minLength') + 3},
                    {max: 255, message: _vm.$t('messages.maxLength') + 255}],
                  }]),expression:"['nfc_badge_id', {rules: [\n                    {required: false, message: $t('messages.requiredField')},\n                    {min: 3, message: $t('messages.minLength') + 3},\n                    {max: 255, message: $t('messages.maxLength') + 255}],\n                  }]"}],attrs:{"size":"large","placeholder":_vm.$t('users.nfc_placehodler')}}),_c('a-input',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"},{name:"decorator",rawName:"v-decorator",value:(['test', {initialValue: _vm.getOrganizationDetails.phone}]),expression:"['test', {initialValue: getOrganizationDetails.phone}]"}],attrs:{"size":"large"}})],1),_c('a-form-item',{attrs:{"label":_vm.$t('picos.user_id')}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['user_id', {rules: [
                    {required: false, message: _vm.$t('messages.requiredField')},
                    {min: 3, message: _vm.$t('messages.minLength') + 3},
                    {max: 255, message: _vm.$t('messages.maxLength') + 255}],
                  }]),expression:"['user_id', {rules: [\n                    {required: false, message: $t('messages.requiredField')},\n                    {min: 3, message: $t('messages.minLength') + 3},\n                    {max: 255, message: $t('messages.maxLength') + 255}],\n                  }]"}],attrs:{"size":"large","placeholder":_vm.$t('users.user_id_placeholder')}})],1)],1)],1)],1),_c('div',{staticClass:"form-action-btn"},[_c('a-button',{on:{"click":_vm.onClose}},[_vm._v(" "+_vm._s(_vm.$t('basics.cancel'))+" ")]),_c('a-button',{attrs:{"type":"primary","loading":_vm.loadingState,"disabled":_vm.loadingState},on:{"click":_vm.handleSubmit}},[_vm._v(" "+_vm._s(_vm.$t('users.create_user'))+" ")])],1)],1)]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }