<template>
  <div class="main-content">
    <div class="main-table-wrapper main-content-wrapp" :class="{'modal-opened': visibleModal}">
      <div class="main-table-block">
        <list-heading>
          <list-title>{{ $t('users.users_list') }}</list-title>
          <div class="action-wrapper">
            <a
              href='/user_template/UserList_Template.xlsx'
              download
              class="ant-btn ant-btn-primary template-btn">
              {{ $t('users.download_template') }}
            </a>
            <a-button
                :loading="importing"
                onclick="document.getElementById('users-import-input').click();"
                type="primary"
                class="orange-btn">
              {{ $t('users.upload_file') }}
            </a-button>
            <a-button
                :loading="creating"
                type="primary"
                class="orange-btn"
                style="margin-left: 20px"
                @click="toggleCreateUserModal(true)"
            >
              {{ $t('users.create_user') }}
            </a-button>
          </div>
          <input id="users-import-input" type="file" name="file" :disabled="importing" @change="filesChange($event.target.name, $event.target.files)"
                 accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" class="input-file" style="display:none">
        </list-heading>
        <div class="admin-layout__list-filters">
          <search
              :placeholderText="searchPlaceholder"
              @select="searchInList"></search>
          <div class="admin-layout__filters">
            <table-size-changer
                :defaultPageSize="pageSize"
                @select="setPageSize"/>
            <list-filter
                :organizations="organizationsDropdownList"
                :checkboxes="filterCheckboxes"
                with-empty-organization
                @filterBy="filterList"
                filteredType="User"
                label="Organization"
            />
          </div>
        </div>
        <a-table
            :locale={emptyText:emptyText}
            class="main-table"
            :loading="loading"
            :columns="columns"
            rowKey="key"
            :pagination="false"
            :data-source="usersList"
            @change="handleTableChange"
            :scroll="{y: 'calc(100% - 40px)' }"
        >
          <template slot="user_id" slot-scope="text, record">
            <div v-if="record.user_id === null">--</div>
            <div v-else>{{record.user_id}}</div>
          </template>
          <template slot="profile" slot-scope="text, record">
            <div v-if="record.profile === null"></div>
            <div v-else>{{record.profile.profile}}</div>
          </template>
          <template slot="status" slot-scope="text, record">
            <div class="status"
              :class="{
                'is-active': record.status.id === 1,
                'is-inactive': record.status.id === 2,
                'is-deleted': record.status.id === 3}">
                <span v-if="record.status.id === 1">{{$t('basics.status.active')}}</span>
                <span v-if="record.status.id === 2">{{$t('basics.status.inactive')}}</span>
                <span v-if="record.status.id === 3">{{$t('basics.status.deleted')}}</span>
              </div>
          </template>
          <template slot="picos" slot-scope="text">
            {{ text }}
          </template>
          <template slot="picos_history" slot-scope="text">
            {{ text }}
          </template>
          <template slot="action" slot-scope="text, record">
            <a-button
                @click="setDetailModalState(true, record.id, record.organization_id)"
                type="primary">
              {{ $t('organizations.details') }}
            </a-button>
          </template>
        </a-table>
      </div>
      <pagination
          @change="setPageNumber"
          :defaultPageNumber="pageNumber"
          :paginationData="pagination"
      />
    </div>
    <user-profile
      v-if="visibleDetail"
      @detailModalState="setDetailModalState"
      @updateList="fetchData"
      :visibleDetail="visibleDetail"
      :organizationsList="organizationsDropdownList"
    />
    <new-user
      v-if="visibleNewUser"
      @created="toggleCreateUserModal(false, true)"
      @close="toggleCreateUserModal(false)"
    />
  </div>
</template>

<script>
import _ from 'lodash';
import { formatDate } from '@/utils/helpers/dateFormat';
import ListTitle from '@/components/ListTitle/ListTitle'
import ListHeading from '@/components/ListHeading/ListHeading'
import Search from '@/components/Search/Search'
import TableSizeChanger from '@/components/TableSizeChanger/TableSizeChanger'
import ListFilter from '@/components/ListFilter/ListFilter'
import Pagination from '@/components/Pagination/Pagination'
import { defaulVariablesUsers } from '@/utils/defaulVeriables';
import UserProfile from "../UserProfile/UserProfile";
import NewUser from "./NewUser";
import { i18n } from '@/i18n';

const columns = [
  {
    title: i18n.t('picos.user_id'),
    dataIndex: 'user_id',
    key: 'user_id',
    sorter: true,
    scopedSlots: { customRender: 'user_id' },
  },
  {
    title: i18n.t('users.user_email'),
    dataIndex: 'email',
    key: 'email',
    sorter: true,
    scopedSlots: { customRender: 'email' }
  },
  {
    title: i18n.t('bases.linked_organization'),
    dataIndex: 'organization_name',
    key: 'organization_name',
    sorter: true,
    scopedSlots: { customRender: 'organization_name' }
  },
  {
    title: i18n.t('organizations.step_1.user_category'),
    dataIndex: 'profile',
    key: 'profile',
    sorter: false,
    scopedSlots: { customRender: 'profile' }
  },
  {
    title: i18n.t('basics.status_title'),
    dataIndex: 'status',
    key: 'status',
    width: '15%',
    sorter: true,
    scopedSlots: { customRender: 'status' }
  },
  {
    title: i18n.t('basics.picos'),
    dataIndex: 'active_picos',
    key: 'picos',
    width: '15%',
    sorter: true,
    scopedSlots: { customRender: 'picos' }
  },
  {
    title: i18n.t('basics.picos_history'),
    dataIndex: 'picos_history',
    key: 'picos_history',
    width: '15%',
    sorter: true,
    scopedSlots: { customRender: 'picos_history' }
  },
  {
    dataIndex: 'action',
    key: 'action',
    width: '15%',
    scopedSlots: { customRender: 'action' }
  }
];

export default {
  name: 'UsersList',
  components: {
    TableSizeChanger,
    ListTitle,
    ListHeading,
    Search,
    ListFilter,
    Pagination,
    UserProfile,
    NewUser
  },
  data() {
    return {
      importing: false,
      creating: false,
      formatDate,
      loading: false,
      searchPlaceholder: this.$t('users.search_placeholder'),
      columns,
      filterCheckboxes: defaulVariablesUsers,
      visibleModal: false,
      visibleDetail: false,
      visibleNewUser: false,
      endpoint: 'GetUsersList',
      emptyText: this.$t('users.empty_text'),
    }
  },
  props: {
    placeholderText: {
      type: String,
      required: false,
      default: ''
    },
    defaultPageSize: {
      type: Number,
      required: false,
      default: null
    },
    defaultPageNumber: {
      type: Number,
      required: false,
      default: null
    },
    paginationData: {
      type: Object,
      required: false,
      default: null
    },
    dataSource: {
      type: Array,
      required: false,
      default: null
    },
    organizations: {
      type: Array,
      required: false,
      default: null
    },
    checkboxes: {
      type: Array,
      required: false,
      default: null
    },
    columnsData: {
      type: Array,
      required: false,
      default: null
    },
    emptyTableText: {
      type: String,
      required: false,
      default: i18n.t('bases.no_data')
    }
  },
  mounted() {
    this.fetchData();
    this.$store.dispatch('organizations/GetOrganizationDropdownList');
  },
  computed: {
    usersList() {
      return this.$store.getters['users/getUsersTableData'];
    },
    getRole() {
      return parseInt(this.$store.getters['getRole'])
    },
    pageSize() {
      return this.$store.getters['users/getPageSize'];
    },
    pageNumber() {
      return this.$store.getters['users/getPageNumber'];
    },
    pagination() {
      const { meta } = this.$store.getters['users/getUsersList']
      return meta ? meta : {}
    },
    organizationsDropdownList() {
      return this.$store.getters['organizations/getOrganizations']
    }
  },
  methods: {
    filesChange(name, files){
      const file = files[0]
      let formData = new FormData();
      formData.append('file', file);
      this.importing = true
      this.$store.dispatch('users/ImportUsers', formData)
        .then((res) => {
          this.$message.success(this.$t('users.users_import_started'))
          this.importing = false
        }).catch((error) => {
          const errors = error.response.data.error.errors

          for (const [key, message] of Object.entries(errors)) {
            const row = key.split('.')
            this.$message.error('Error! Row #' + row[row.length - 1] + '. ' + message)
          }
          this.importing = false
        })
    },
    fetchData(value) {
      this.loading = true;
      this.$store.dispatch('users/GetUsersList', value ? value : {})
          .then((res)=> { this.loading = false })
    },
    setPageSize(value) {
      this.$store.commit('users/SET_PAGE_SIZE', value)
      this.fetchData()
    },
    setPageNumber(value) {
      this.fetchData({page: value})
    },
    filterList(params) {
      this.$store.commit('users/SET_FILTERS', params);
      this.fetchData({params})
    },
    handleTableChange(pagination, filters, sorter) {
      var sortOrderName = sorter.order === 'ascend' ? 'asc' : 'desc',
          sortParams = {sortField: sorter.field, sortOrder: sortOrderName};

      sortParams = sorter.order ? sortParams : {}
      this.$store.commit('users/SET_SORT', sortParams)
      this.fetchData(sortParams)
    },
    searchInList: _.debounce(function (value) {
      this.$store.commit('users/SET_SEARCH_VALUE', value);
      if(value.trim().length >= 3) {
        this.fetchData({ search: value })
      } else if(value === '') {
        this.fetchData()
      }
    }, 700),
    setActiveModal(value) {
      this.visibleModal = value
    },
    setDetailModalState(value, itemID, orgID) {
      this.visibleDetail = value
      if (itemID) {
        this.$store.dispatch('detailUser/GetUser', {id:itemID, organizationId:orgID})
      }
    },
    toggleCreateUserModal(state, updateData = false) {
      this.visibleNewUser = state;

      if (updateData) {
        this.fetchData();
      }
    }
  }
}
</script>
<style scoped>
.template-btn {
  margin-right: 20px;
}
</style>

